
import { defineComponent, PropType } from "vue";
import variables from "@/router/api";

export default defineComponent({
  name: "claim-settlement-offer-modal-component",
  components: {},
  props: {
    claimReference: String,
    settlementOffers: Array as PropType<Record<string, any>[]>,
    role: String,
  },
  setup() {
    const addCommaToNumberString = (amount: any) => {
      return variables.addCommaToNumberString(amount);
    };
    return { addCommaToNumberString };
  },
});
